// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abc-inspiracji-js": () => import("./../src/pages/abc-inspiracji.js" /* webpackChunkName: "component---src-pages-abc-inspiracji-js" */),
  "component---src-pages-dodaj-inspiracje-js": () => import("./../src/pages/dodaj-inspiracje.js" /* webpackChunkName: "component---src-pages-dodaj-inspiracje-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sprawdz-js": () => import("./../src/pages/sprawdz.js" /* webpackChunkName: "component---src-pages-sprawdz-js" */)
}

